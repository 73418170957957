import clsx from 'clsx';
import LogoLoader from './LogoLoader';
import './FullWidthLoader.scss';

interface Props {
  className?: string;
  color?: 'motional' | 'subtle';
  size?: 'medium' | 'small';
}

function FullWidthLoader({
  className,
  color = 'motional',
  size = 'medium',
}: Props) {
  return (
    <span
      role="progressbar"
      className={clsx([
        className,
        'full-width-loader',
        `full-width-loader--style-${color}`,
        `full-width-loader--size-${size}`,
      ])}
    >
      <LogoLoader className="full-width-loader__loader" />
    </span>
  );
}

export default FullWidthLoader;
