import clsx from 'clsx';
import { ReactNode } from 'react';
import './EmptyState.scss';

export const EMPTY_STATE_DESCRIPTION_CLASS = 'empty-state__description';

interface Props {
  className?: string;
  title: string;
  description?: ReactNode;
}

function EmptyState({ className, title, description }: Props) {
  return (
    <div className={clsx([className, 'empty-state'])}>
      <h5 className="empty-state__title">{title}</h5>

      {!description ?
        null
      : typeof description === 'string' ?
        <p className={EMPTY_STATE_DESCRIPTION_CLASS}>{description}</p>
      : Array.isArray(description) ?
        description.map((paragraph, paragraphIndex) => (
          <p key={paragraphIndex} className={EMPTY_STATE_DESCRIPTION_CLASS}>
            {paragraph}
          </p>
        ))
      : description}
    </div>
  );
}

export default EmptyState;
