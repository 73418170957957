import upperFirst from 'lodash/upperFirst';
import { Immutable } from 'src/interface/utility';
import deepClone from 'src/tools/object/deepClone';
import { create } from 'zustand';

const createStore = <
  // I tried using unknown, but it messes with `Immutable`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InitialStatePartial extends Immutable<{ [key: string]: any }>,
  InitialState extends
    Required<InitialStatePartial> = InitialStatePartial extends (
    Required<InitialStatePartial>
  ) ?
    Required<InitialStatePartial>
  : never,
>(
  initialState: InitialState,
) => {
  type FullType = InitialState & {
    [Key in keyof InitialState as `set${Capitalize<string & Key>}`]: (
      newValue:
        | InitialState[Key]
        | ((previousValue: InitialState[Key]) => InitialState[Key]),
    ) => void;
  } & { resetState: () => void };

  type StateUpdate = Partial<FullType> & InitialState;

  return create<FullType>(
    (set) =>
      ({
        ...deepClone(initialState),

        ...Object.fromEntries(
          Object.keys(initialState).map((key) => [
            `set${upperFirst(key)}`,
            (newValue: unknown | ((previousValue: unknown) => unknown)) =>
              set(
                (state) =>
                  ({
                    [key]:
                      newValue instanceof Function ?
                        newValue(state[key])
                      : newValue,
                  }) as StateUpdate,
              ),
          ]),
        ),

        resetState() {
          set(deepClone(initialState) as StateUpdate);
        },
      }) as FullType,
  );
};

export default createStore;
