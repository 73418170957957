import { ReactNode, SVGProps } from 'react';
import Svg from 'src/components/common/Svg';
import { Props as CcSvgProps } from 'src/components/common/Svg/Svg';

interface SvgAbstractionProps extends Omit<CcSvgProps, 'children'> {
  onlyCc?: boolean;
}
interface LogoSvgProps extends SvgAbstractionProps {
  children: ReactNode;
}

type GroupAbstractionProps = Omit<SVGProps<SVGGElement>, 'children'>;
interface GroupProps extends GroupAbstractionProps {
  children: ReactNode;
}

type PathProps = SVGProps<SVGPathElement>;

export function SvgWrapper({
  className,
  children,
  onlyCc,
  ...svgProps
}: LogoSvgProps) {
  return (
    <Svg
      className={className}
      fill="none"
      stroke="var(--c-logo, currentColor)"
      viewBox={`0 0 ${onlyCc ? 41 : 44} 24`}
      {...svgProps}
    >
      {children}
    </Svg>
  );
}

export function DoubleGroup({
  className,
  children,
  ...groupProps
}: GroupProps) {
  return (
    <g className={className} strokeWidth="2px" {...groupProps}>
      {children}
    </g>
  );
}

export function FatGroup({ className, children, ...groupProps }: GroupProps) {
  return (
    <g className={className} strokeWidth="6px" {...groupProps}>
      {children}
    </g>
  );
}

export const UpperJoinRightOuter = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="upper-join-right-outer"
    className={className}
    d="M20 12c1-3 2.231-5.788 4.222-7.778"
  />
);
export const LowerJoinLeftInner = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="lower-join-left-inner"
    className={className}
    d="M16.95 16.95A13.103 13.103 0 0 0 20 12"
  />
);
export const RightCircleRightInner = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="right-circle-right-inner"
    className={className}
    d="M36.95 16.95C38.216 15.683 39 13.933 39 12s-.784-3.683-2.05-4.95"
  />
);
export const LeftCircleLeftInner = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="left-circle-left-inner"
    className={className}
    d="M16.95 7.05a7 7 0 1 0 0 9.9"
  />
);
export const LowerCircleRightOuter = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="lower-circle-right-outer"
    className={className}
    d="M24.222 19.778C26.212 21.768 28.962 23 32 23s5.788-1.231 7.778-3.222"
  />
);
export const UpperCircleRightOuter = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="upper-circle-right-outer"
    className={className}
    d="M39.778 4.222C37.788 2.232 35.038 1 32 1s-5.788 1.231-7.778 3.222"
  />
);
export const UpperCircleRightInner = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="upper-circle-right-inner"
    className={className}
    d="M36.95 7.05C35.683 5.784 33.933 5 32 5s-3.683.784-4.95 2.05"
  />
);
export const RightCircleRightOuter = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="right-circle-right-outer"
    className={className}
    d="M39.778 19.778C41.768 17.788 43 15.038 43 12s-1.231-5.788-3.222-7.778"
  />
);
export const LeftCircleRightInner = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="left-circle-right-inner"
    className={className}
    d="M27.05 7.05C25.784 8.317 25 10.067 25 12s.784 3.683 2.05 4.95"
  />
);
export const LowerJoinLeftOuter = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="lower-join-left-outer"
    className={className}
    d="M24 12c-1 3-2.231 5.788-4.222 7.778"
  />
);
export const UpperJoinRightInner = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="upper-join-right-inner"
    className={className}
    d="M27.05 7.05A13.103 13.103 0 0 0 24 12"
  />
);
export const LowerCircleRightInner = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="lower-circle-right-inner"
    className={className}
    d="M27.05 16.95C28.317 18.216 30.067 19 32 19s3.683-.784 4.95-2.05"
  />
);
export const LeftCircleLeftOuter = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="left-circle-left-outer"
    className={className}
    d="M19.778 19.778A10.966 10.966 0 0 1 12 23C5.925 23 1 18.075 1 12S5.925 1 12 1c3.038 0 5.788 1.231 7.778 3.222"
  />
);
export const LeftCircleRightClippedPiece = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="left-circle-right-clipped-piece"
    className={className}
    d="M22.045 16.656a11.02 11.02 0 0 0 2.177 3.122"
  />
);

export const DoubleParts = ({
  className,
  ...groupWrapperProps
}: GroupAbstractionProps) => (
  <DoubleGroup className={className} {...groupWrapperProps}>
    <UpperJoinRightOuter />
    <LowerJoinLeftInner />
    <RightCircleRightInner />
    <LeftCircleLeftInner />
    <LowerCircleRightOuter />
    <UpperCircleRightOuter />
    <UpperCircleRightInner />
    <RightCircleRightOuter />
    <LeftCircleRightInner />
    <LowerJoinLeftOuter />
    <UpperJoinRightInner />
    <LowerCircleRightInner />
    <LeftCircleLeftOuter />
    <LeftCircleRightClippedPiece />
  </DoubleGroup>
);

export const DoubleCcPart1 = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="double-cc-part-1"
    className={className}
    d="M39.778 4.222C37.788 2.232 35.038 1 32 1s-5.788 1.231-7.778 3.222S21 9 20 12c-.632 1.897-1.784 3.683-3.05 4.95S13.933 19 12 19a7 7 0 1 1 4.95-11.95"
  />
);
export const DoubleCcPart2 = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="double-cc-part-2"
    className={className}
    d="M19.778 4.222A10.966 10.966 0 0 0 12 1C5.925 1 1 5.925 1 12s4.925 11 11 11c3.038 0 5.788-1.231 7.778-3.222S23 15 24 12c.632-1.897 1.784-3.683 3.05-4.95S30.067 5 32 5s3.683.784 4.95 2.05"
  />
);
export const DoubleCcPart3 = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="double-cc-part-3"
    className={className}
    d="M22.045 16.656a11.02 11.02 0 0 0 2.177 3.122C26.212 21.768 28.962 23 32 23s5.788-1.231 7.778-3.222"
  />
);
export const DoubleCcPart4 = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="double-cc-part-4"
    className={className}
    d="M27.05 7.05C25.784 8.317 25 10.067 25 12s.784 3.683 2.05 4.95C28.317 18.216 30.067 19 32 19s3.683-.784 4.95-2.05"
  />
);

export const DoubleCcParts = ({ className }: PathProps) => (
  <DoubleGroup className={className}>
    <DoubleCcPart1 />
    <DoubleCcPart2 />
    <DoubleCcPart3 />
    <DoubleCcPart4 />
  </DoubleGroup>
);

export const DoubleCc = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="double-cc"
    className={className}
    d="M39.778 4.222C37.788 2.232 35.038 1 32 1s-5.788 1.231-7.778 3.222S21 9 20 12c-.632 1.897-1.784 3.683-3.05 4.95S13.933 19 12 19a7 7 0 1 1 4.95-11.95m10.1 0C25.784 8.317 25 10.067 25 12s.784 3.683 2.05 4.95C28.317 18.216 30.067 19 32 19s3.683-.784 4.95-2.05M19.778 4.222A10.966 10.966 0 0 0 12 1C5.925 1 1 5.925 1 12s4.925 11 11 11c3.038 0 5.788-1.231 7.778-3.222S23 15 24 12c.632-1.897 1.784-3.683 3.05-4.95S30.067 5 32 5s3.683.784 4.95 2.05m-14.905 9.606a11.02 11.02 0 0 0 2.177 3.122C26.212 21.768 28.962 23 32 23s5.788-1.231 7.778-3.222"
  />
);

export const DoubleInfinityTopRightOver = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="double-infinity-top-right-over"
    className={className}
    d="M20 12a13.103 13.103 0 0 0-3.05-4.95 7 7 0 1 0 0 9.9A13.103 13.103 0 0 0 20 12c1-3 2.231-5.788 4.222-7.778S28.962 1 32 1s5.788 1.231 7.778 3.222S43 8.962 43 12s-1.231 5.788-3.222 7.778S35.038 23 32 23s-5.788-1.231-7.778-3.222c-.874-.874-1.596-1.908-2.222-3.04m0-9.476c-.626-1.132-1.348-2.166-2.222-3.04A10.966 10.966 0 0 0 12 1C5.925 1 1 5.925 1 12s4.925 11 11 11c3.038 0 5.788-1.231 7.778-3.222S23 15 24 12c.632-1.897 1.784-3.683 3.05-4.95S30.067 5 32 5s3.683.784 4.95 2.05C38.216 8.317 39 10.067 39 12s-.784 3.683-2.05 4.95S33.933 19 32 19s-3.683-.784-4.95-2.05A13.103 13.103 0 0 1 24 12"
  />
);

export const DoubleInfinityTopLeftOver = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="double-infinity-top-left-over"
    className={className}
    d="M22 7.262c.626-1.132 1.348-2.166 2.222-3.04C26.212 2.232 28.962 1 32 1s5.788 1.231 7.778 3.222S43 8.962 43 12s-1.231 5.788-3.222 7.778S35.038 23 32 23s-5.788-1.231-7.778-3.222S21 15 20 12a13.103 13.103 0 0 0-3.05-4.95 7 7 0 1 0 0 9.9A13.103 13.103 0 0 0 20 12m4 0c.632-1.897 1.784-3.683 3.05-4.95S30.067 5 32 5s3.683.784 4.95 2.05C38.216 8.317 39 10.067 39 12s-.784 3.683-2.05 4.95S33.933 19 32 19s-3.683-.784-4.95-2.05A13.103 13.103 0 0 1 24 12c-1-3-2.231-5.788-4.222-7.778S15.038 1 12 1C5.925 1 1 5.925 1 12s4.925 11 11 11c3.038 0 5.788-1.231 7.778-3.222.874-.874 1.596-1.908 2.222-3.04"
  />
);

export const DoubleAll = ({ className }: PathProps) => (
  <DoubleGroup className={className}>
    <DoubleParts />
    <DoubleCcParts />
    <DoubleCc />
    <DoubleInfinityTopRightOver />
    <DoubleInfinityTopLeftOver />
  </DoubleGroup>
);

export const FatLeftCircleLeft = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="fat-left-circle-left"
    className={className}
    d="M18.364 18.364a9 9 0 1 1 0-12.728"
  />
);

export const FatRightCircleLeft = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="fat-right-circle-left"
    className={className}
    d="M18.364 5.636a9 9 0 0 1 0 12.728"
  />
);

export const FatLowerCircleRight = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="fat-lower-circle-right"
    className={className}
    d="M38.364 18.364a9 9 0 0 1-12.728 0"
  />
);

export const FatRightCircleRight = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="fat-right-circle-right"
    className={className}
    d="M38.364 5.636a9 9 0 0 1 0 12.728"
  />
);

export const FatUpperCircleRight = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="fat-upper-circle-right"
    className={className}
    d="M25.636 5.636a9 9 0 0 1 12.728 0"
  />
);

export const FatLeftCircleRight = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="fat-left-circle-right"
    className={className}
    d="M25.636 18.364a9 9 0 0 1 0-12.728"
  />
);

export const FatUpperJoinLeft = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="fat-upper-join-left"
    className={className}
    d="M25.636 18.364c-4.5-4.5-2.772-8.228-7.272-12.728"
  />
);
export const FatUpperJoinRight = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="fat-upper-join-right"
    className={className}
    d="M25.636 5.636c-4.5 4.5-2.772 8.228-7.272 12.728"
  />
);
export const FatParts = ({
  className,
  ...groupWrapperProps
}: GroupAbstractionProps) => (
  <FatGroup className={className} {...groupWrapperProps}>
    <FatLeftCircleLeft />
    <FatRightCircleLeft />
    <FatLowerCircleRight />
    <FatRightCircleRight />
    <FatUpperCircleRight />
    <FatLeftCircleRight />
    <FatUpperJoinLeft />
    <FatUpperJoinRight />
  </FatGroup>
);

export const FatInfinity = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="fat-infinity"
    className={className}
    d="M18.364 5.636c4.5 4.5 2.772 8.228 7.272 12.728a9 9 0 1 0 0-12.728c-4.5 4.5-2.772 8.228-7.272 12.728a9 9 0 1 1 0-12.728Z"
  />
);
export const FatCc = ({ className }: PathProps) => (
  <path
    data-logo-svg-part="fat-cc"
    className={className}
    d="M38.364 18.364A9 9 0 1 1 25.636 5.636m-7.272 0a9 9 0 1 0 0 12.728c4.5-4.5 2.772-8.228 7.272-12.728a9 9 0 0 1 12.728 0"
  />
);

export const FatAll = ({
  className,
  ...groupWrapperProps
}: GroupAbstractionProps) => (
  <FatGroup className={className} {...groupWrapperProps}>
    <FatParts />
    <FatInfinity />
    <FatCc />
  </FatGroup>
);

export function StaticCcLogoSvg({
  className,
  ...svgWrapperProps
}: SvgAbstractionProps) {
  return (
    <SvgWrapper className={className} {...svgWrapperProps}>
      <DoubleCc />
    </SvgWrapper>
  );
}

export function StaticCcLogoInPartsSvg({
  className,
  ...svgWrapperProps
}: SvgAbstractionProps) {
  return (
    <SvgWrapper className={className} {...svgWrapperProps}>
      <DoubleCcParts />
    </SvgWrapper>
  );
}

function AllPieces({ className, ...svgWrapperProps }: SvgAbstractionProps) {
  return (
    <SvgWrapper className={className} {...svgWrapperProps}>
      <DoubleAll />
      <FatAll />
    </SvgWrapper>
  );
}

export default AllPieces;
