import clsx from 'clsx';
import { ReactNode } from 'react';
import TableCell from 'src/components/common/Table/TableCell';

interface Props {
  colSpan: number;
  faded?: boolean;
  children: ReactNode;
}

export function RowDrawer({ colSpan, faded = true, children }: Props) {
  return (
    <tr
      className={clsx([
        'the-table__row the-table__row--drawer',
        { 'the-table__row the-table__row--faded': faded },
      ])}
    >
      <TableCell colSpan={colSpan}>
        <div className="the-table__row-contents">{children}</div>
      </TableCell>
    </tr>
  );
}
