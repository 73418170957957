import type {
  AlertLevel,
  BenignAlertLevel,
  DataLoadStatus,
  FullJobBody,
} from './unsorted-types';
import type {
  CarComponents,
  LegacyCarComponents,
  TaskEvent,
} from '@motional-cc/fe/interface/api/api-concierge';
import type {
  Job,
  JobRequest,
  JobRequestBody,
  MaintenanceJob,
  RideshareJob,
} from '@motional-cc/fe/interface/api/kamaji';
import type {
  GEN1Vehicle,
  GEN2Vehicle,
  GenericPackage,
  Pkg,
  Vehicle as RegistrarVehicle,
} from '@motional-cc/fe/interface/api/registrar';
import {
  CommandState,
  PendingCommandState,
  RejectedCommandState,
} from '@motional-cc/fe/interface/api/api-cerebro';
import { isNullish } from 'src/tools/types';
import {
  BENIGN_ALERT_LEVELS,
  JOB_SUBTYPES,
  PENDING_COMMAND_STATES,
  REJECTED_COMMAND_STATES,
  REQUIRED_MAINTENENCE_PROPS,
  REQUIRED_OTHER_PROPS,
  REQUIRED_RIDESHARE_PROPS,
} from './unsorted-type-arrays';

export const GENERIC_FORMAT = 'generic_package' as const;
export const isGenericPackage = (pkg: Pkg): pkg is GenericPackage =>
  pkg.target_format === GENERIC_FORMAT;

// export const isLegacyVehicle = (
//   vehicle: RegistrarVehicle,
// ): vehicle is LegacyVehicle => vehicle.platform === 'BMW';

export const isGEN1Vehicle = (
  vehicle?: Pick<RegistrarVehicle, 'platform'> | null,
): vehicle is GEN1Vehicle => !!vehicle?.platform?.startsWith('GEN1');

export const isGEN2Vehicle = (
  vehicle?: Pick<RegistrarVehicle, 'platform'> | null,
): vehicle is GEN2Vehicle => !!vehicle?.platform?.startsWith('GEN2');

// export const isGEN3Vehicle = (
//   vehicle: RegistrarVehicle,
// ): vehicle is GEN3Vehicle => vehicle.platform?.startsWith('GEN3');

export const isIncompleteStatus = (
  status: DataLoadStatus,
): status is 'pending' => ['pending'].includes(status);

export const isMaintenanceJob = (
  jobRequest: Partial<JobRequestBody>,
): jobRequest is MaintenanceJob => {
  if (jobRequest.type !== 'maintenance') {
    return false;
  }
  for (const prop of REQUIRED_MAINTENENCE_PROPS) {
    if (!(prop in jobRequest) || jobRequest[prop] === undefined) {
      return false;
    }
  }
  return true;
};

export const isFullJob = (
  jobRequest: Partial<JobRequestBody>,
): jobRequest is FullJobBody => {
  if (
    !jobRequest.type ||
    !jobRequest.subtype ||
    jobRequest.type === 'rideshare' ||
    jobRequest.type === 'maintenance'
  ) {
    return false;
  }
  for (const prop of REQUIRED_OTHER_PROPS) {
    if (!(prop in jobRequest) || jobRequest[prop] === undefined) {
      return false;
    }
  }
  return (
    jobRequest.subtype === 'other' ||
    JOB_SUBTYPES[jobRequest.type].includes(jobRequest.subtype)
  );
};

export const isJob = (job: JobRequest | Job): job is Job =>
  'job_request_id' in job && 'vehicle' in job;

export const isModernCarComponents = (
  carComponents: CarComponents | LegacyCarComponents,
): carComponents is CarComponents =>
  isNullish(
    (carComponents as LegacyCarComponents).nuCarClient ||
      (carComponents as LegacyCarComponents).nuCarStateManager ||
      (carComponents as LegacyCarComponents).nuCarCamVideoStreamer,
  );

export const isBenignAlertLevel = (
  alertLevel: AlertLevel,
): alertLevel is BenignAlertLevel =>
  BENIGN_ALERT_LEVELS.includes(alertLevel as BenignAlertLevel);

export const isSameTaskEvent = (task1?: TaskEvent, task2?: TaskEvent) =>
  !!(task1 && task2) &&
  task1.name === task2.name &&
  task1.goalId === task2.goalId;

export const isRideshareJob = (
  jobRequest: Partial<JobRequestBody>,
): jobRequest is RideshareJob => {
  if (jobRequest.type !== 'rideshare') {
    return false;
  }
  for (const prop of REQUIRED_RIDESHARE_PROPS) {
    if (!(prop in jobRequest) || jobRequest[prop] === undefined) {
      return false;
    }
  }
  return true;
};

export const isRejectedCommandState = (
  state?: CommandState,
): state is RejectedCommandState =>
  !!state && REJECTED_COMMAND_STATES.includes(state as RejectedCommandState);

export const isPendingCommandState = (
  state?: CommandState,
): state is PendingCommandState =>
  !!state && PENDING_COMMAND_STATES.includes(state as PendingCommandState);
