import { objectEntries } from 'src/tools/object/objectEntries';
import { randomHexBytes } from './randomHexBytes';
import { TraceParentId } from './types';

type TraceItem = {
  latestFullUrl: string;
  traceId: string;
  callCount: number;
  latestDate: Date;
};

class TraceMap {
  #store: { [UrlBase: string]: TraceItem };

  get latest() {
    return objectEntries(this.#store)
      .sort(
        ([, { latestDate: timestampA }], [, { latestDate: timestampB }]) =>
          timestampA.getTime() - timestampB.getTime(),
      )
      .slice(-11)
      .map(([, value]) => ({
        url: value.latestFullUrl,
        traceId: value.traceId,
        callCount: value.callCount,
        timestamp: value.latestDate.getTime(),
      }));
  }

  add(url: string, traceId: string) {
    const path = url.split(/[?#]/)[0];

    const matchingEntry = objectEntries(this.#store).find(
      ([url]) => url === path,
    )?.[1];

    const result = (this.#store[path] = {
      traceId,
      latestDate: new Date(),
      latestFullUrl: url,
      callCount: (matchingEntry?.callCount || 0) + 1,
    });

    return result;
  }

  constructor() {
    this.#store = {};
  }
}

export const traceMap = new TraceMap();

// export const INVALID_TRACE_ID: TraceHeader =
// '00-00000000000000000000000000000000-0000000000000000-00' as const;
export const SESSION_TRACE_PARENT: TraceParentId = randomHexBytes(8);
