import uniq from 'lodash/uniq';

// Used as:
// assertArrayExhaustsType<'a' | 'b'>()([ 'a' ]); => error
// assertArrayExhaustsType<'a' | 'b'>()([ 'a', 'b' ]); => safe
// assertArrayExhaustsType<'a' | 'b'>()([ 'a', 'b', 'c' ]); => error
const assertArrayExhaustsType =
  <TypeToExhaust>() =>
  <PartialArray extends TypeToExhaust[]>(
    array: Readonly<
      PartialArray &
        ([TypeToExhaust] extends [PartialArray[number]] ? unknown : 'Invalid')
    >,
  ): Readonly<TypeToExhaust[]> =>
    uniq(array);

export default assertArrayExhaustsType;
