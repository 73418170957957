import clsx from 'clsx';
import { ReactNode } from 'react';

interface Props {
  className?: string;
  children: ReactNode;
}

function TableToolBars({ className, children }: Props) {
  return (
    <div className={clsx([className, 'the-table__toolbar-rows'])}>
      {children}
    </div>
  );
}

export default TableToolBars;
