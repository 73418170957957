import clsx from 'clsx';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import './LinkButton.scss';

interface Props {
  children: ReactNode;
  className?: string;
  autoFocus?: boolean;
  isDisabled?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick: () => Promise<void> | void;
}

function LinkButton({
  onClick,
  children,
  type = 'button',
  className,
  autoFocus,
  isDisabled,
}: Props) {
  const handleClick = () => {
    if (isDisabled) return;

    onClick?.();
  };

  return (
    <button
      onClick={handleClick}
      autoFocus={autoFocus}
      aria-disabled={isDisabled}
      type={
        type === 'button' ? 'button'
        : type === 'submit' ?
          'submit'
        : 'reset'
      }
      className={clsx([
        'link-button',
        className,
        {
          'link-button--disabled': isDisabled,
        },
      ])}
    >
      <span className="link-button__content">{children}</span>
    </button>
  );
}

export default LinkButton;
