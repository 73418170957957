import clsx from 'clsx';
import { ReactNode } from 'react';
import { ColumnComponentDefinition } from './interface';

export const CONTENT_CLASS = 'the-table__cell-content';

interface Props {
  className?: string;
  children?: ReactNode;
  column?: ColumnComponentDefinition;
  colSpan?: number;
}

function TableCell({ className, children, column, colSpan }: Props) {
  return (
    <td
      colSpan={colSpan}
      className={clsx([
        className,
        'the-table__cell',
        { 'the-table__cell--is-locked-left': column?.isLockedLeft },
        { 'the-table__cell--is-locked-right': column?.isLockedRight },
      ])}
    >
      {children}
    </td>
  );
}

export default TableCell;
