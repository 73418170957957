import { SVGProps as ExhaustiveSvgProps } from 'react';

export interface Props
  extends Omit<
    ExhaustiveSvgProps<SVGSVGElement>,
    'version' | 'xmlns' | 'xmlnsXlink' | 'baseProfile'
  > {
  cssCustomProperties?: {
    [PropName: `--${string}`]: string | number;
  };
}

function Svg({ children, cssCustomProperties, ...otherSvgProps }: Props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={cssCustomProperties}
      {...otherSvgProps}
    >
      {children}
    </svg>
  );
}

export default Svg;
