import sum from 'lodash/sum';
import { SESSION_TRACE_PARENT } from './constants';
import { decToHex2 } from './decToHex2';
import { randomHexBytes } from './randomHexBytes';
import { Hex2, TraceParentId, TraceId, TraceHeader } from './types';

// See https://www.w3.org/TR/trace-context/ for details

const traceFlags = {
  sampled: 0b00000001,
  // tbd1: 0b00000010,
  // tbd2: 0b00000100,
  // tbd3: 0b00001000,
  // tbd4: 0b00010000,
  // tbd5: 0b00100000,
  // tbd6: 0b01000000,
  // tbd7: 0b10000000,
} as const;

export const buildTraceId = ({
  versionByte = '00',
  traceParentId = SESSION_TRACE_PARENT,
  traceId = randomHexBytes(16),
  flags = [traceFlags.sampled],
}: {
  versionByte?: Hex2;
  traceParentId?: TraceParentId;
  traceId?: TraceId;
  flags?: number[];
} = {}): TraceHeader =>
  `${versionByte}-${traceId}-${traceParentId}-${
    flags ? decToHex2(sum(flags)) : '00'
  }`;
