import { useEffect, useState } from 'react';

export default function useDebouncedValue<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    function setValueAfterDelay() {
      const debounceTimeout = window.setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(debounceTimeout);
      };
    },
    [value, delay],
  );

  return debouncedValue;
}
