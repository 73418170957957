import clsx from 'clsx';
import compact from 'lodash/compact';
import get from 'lodash/get';
import { CSSProperties, Fragment, ReactNode, useState } from 'react';
import Button from 'src/components/common/Button';
import Checkbox from 'src/components/common/Checkbox';
import Icon from 'src/components/common/Icon';
import TableCell, {
  CONTENT_CLASS,
} from 'src/components/common/Table/TableCell';
import { ColumnDefinition } from './interface';
import { RowDrawer } from './RowDrawer';

type Props<RowData> = {
  row: RowData;
  rowId: string;
  columns: ColumnDefinition[];
  renderDrawer?: (rowData: RowData) => ReactNode;
  onDrawerToggle?: (rowId: string, newValue: boolean) => void;
  checked?: boolean;
  onCheckboxCheck?: (rowId: string) => void;
  initallyOpenDrawer?: boolean | ((rowData: RowData) => boolean);
};

function TableRow<RowData>({
  row,
  rowId,
  columns,
  checked = false,
  onCheckboxCheck,
  onDrawerToggle,
  renderDrawer,
  initallyOpenDrawer,
}: Props<RowData>) {
  const [drawerOpen, setDrawerOpen] = useState(
    typeof initallyOpenDrawer === 'boolean' ? initallyOpenDrawer
    : typeof initallyOpenDrawer === 'function' ? initallyOpenDrawer(row)
    : false,
  );

  const extraColumnCount = compact([onCheckboxCheck, renderDrawer]).length;
  const totalColumnCount = columns.length + extraColumnCount;

  const handleOpenToggle = () => {
    setDrawerOpen((drawerOpen) => {
      onDrawerToggle?.(rowId, !drawerOpen);
      return !drawerOpen;
    });
  };

  const handleCheckboxCheck = () => {
    onCheckboxCheck?.(rowId);
  };

  return (
    <>
      <tr
        data-cy={`row-${rowId}`}
        className={clsx([
          'the-table__row',
          { 'the-table__row--drawer-open': drawerOpen },
        ])}
      >
        {onCheckboxCheck && (
          <TableCell className="the-table__cell--action">
            <span className={CONTENT_CLASS}>
              <Checkbox
                hideLabel
                label={`Select ${rowId}`}
                checked={checked}
                onChange={handleCheckboxCheck}
              />
            </span>
          </TableCell>
        )}

        {renderDrawer && (
          <TableCell className="the-table__cell--action">
            <Button
              insetFocus
              color="text"
              aria-label={`Toggle drawer for ${rowId}`}
              onClick={handleOpenToggle}
            >
              <span
                className={clsx([CONTENT_CLASS, `${CONTENT_CLASS}--action`])}
                style={{ '--icon-size': `16px` } as CSSProperties}
              >
                <Icon
                  className="the-table__drawer-toggle-icon"
                  name="ChevronDown"
                />
              </span>
            </Button>
          </TableCell>
        )}

        {columns.map(({ CellComponent, ...column }: ColumnDefinition) => (
          <Fragment
            key={compact([
              column.label,
              column.valuePath,
              column.ariaLabel,
            ]).join('--')}
          >
            <CellComponent
              column={column}
              cell={column.valuePath ? get(row, column.valuePath) : row}
            />
          </Fragment>
        ))}
      </tr>

      {renderDrawer && drawerOpen && (
        <RowDrawer colSpan={totalColumnCount}>{renderDrawer(row)}</RowDrawer>
      )}
    </>
  );
}

export default TableRow;
