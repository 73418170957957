import { useEffect, useState } from 'react';

interface Size {
  contentInlineSize: number;
  contentBlockSize: number;
  borderInlineSize: number;
  borderBlockSize: number;
}

export default function useElementSize(
  refElement?: HTMLElement | null,
  onChange?: (newSize: Size) => void,
) {
  const [elementSize, setElementSize] = useState<Size>({
    contentInlineSize: 0,
    contentBlockSize: 0,
    borderInlineSize: 0,
    borderBlockSize: 0,
  });

  useEffect(
    function setupResizeObserver() {
      if (!refElement) {
        setElementSize({
          contentInlineSize: 0,
          contentBlockSize: 0,
          borderInlineSize: 0,
          borderBlockSize: 0,
        });
        return;
      }

      const resizeObserver = new ResizeObserver(([element]) => {
        let contentInlineSize: number;
        let contentBlockSize: number;
        let borderInlineSize: number;
        let borderBlockSize: number;

        // This nonsense is for cross browser compat
        if (element.contentBoxSize) {
          ({ inlineSize: contentInlineSize, blockSize: contentBlockSize } =
            element.contentBoxSize[0] ?? element.contentBoxSize);
          ({ inlineSize: borderInlineSize, blockSize: borderBlockSize } =
            element.borderBoxSize[0] ?? element.borderBoxSize);
        } else {
          ({ width: contentInlineSize, height: contentBlockSize } =
            element.contentRect);
          ({ width: borderInlineSize, height: borderBlockSize } =
            element.target.getBoundingClientRect());
        }
        // end - This nonsense is for cross browser compat

        // Reduce ‘ResizeObserver loop limit exceeded’ errors
        window.requestAnimationFrame(() => {
          const newSize: Size = {
            contentInlineSize,
            contentBlockSize,
            borderInlineSize,
            borderBlockSize,
          };

          setElementSize((previousSize) => {
            const previousSizes = Object.values(previousSize);
            if (
              Object.values(newSize).every(
                (size, index) => previousSizes[index] === size,
              )
            ) {
              return previousSize;
            }
            return newSize;
          });
        });
      });

      resizeObserver.observe(refElement);

      return () => {
        resizeObserver.disconnect();
      };
    },
    [refElement],
  );

  useEffect(
    function notifySizeChange() {
      onChange?.(elementSize);
    },
    [elementSize, onChange],
  );

  return elementSize;
}
