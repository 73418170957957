import { useSyncExternalStore } from 'react';

let windowFocused = false;

window.addEventListener('focus', () => {
  windowFocused = true;
});

window.addEventListener('blur', () => {
  windowFocused = false;
});

const checkIfUserIsActive = () => windowFocused || !document.hidden;

export function useWindowInnerWidth() {
  return useSyncExternalStore(
    (listener) => {
      window.addEventListener('resize', listener);

      return () => {
        window.removeEventListener('resize', listener);
      };
    },
    () => window.innerWidth,
  );
}

export function useWindowIsActive() {
  return useSyncExternalStore((listener) => {
    document.addEventListener('visibilitychange', listener);
    window.addEventListener('focus', listener);
    window.addEventListener('blur', listener);

    return () => {
      document.removeEventListener('visibilitychange', listener);
      window.removeEventListener('focus', listener);
      window.removeEventListener('blur', listener);
    };
  }, checkIfUserIsActive);
}
