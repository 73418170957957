import t, { Interpolations } from '@motional-cc/fe/tools/translate';
import { ApiError } from 'src/interface/errors/api-error';
import { renderStatusMessage } from 'src/tools/http/renderStatusMessage';

export interface Options {
  payload?: Interpolations;
}

export const DEFAULT_ERROR_BRIEF = t('httpCode.name.0');
const DEFAULT_ERROR_DETAILS =
  'We’re unable to determine what went wrong. If the issue continues, submit an issue through the heart button at the top right of the page.';

const renderApiErrorBrief = (
  error: Readonly<ApiError>,
  { payload }: Readonly<Options> = {},
) =>
  (error.code && t(`errorCodes.SHORT.${error.code}`, payload)) ||
  // we only show use error.errors as brief when errors list exists because
  //   message can be quite long, but otherwise we will never show it when errors exists
  (error.errors?.length && error.message) ||
  (error.status && renderStatusMessage(error.status)) ||
  DEFAULT_ERROR_BRIEF;

const renderApiErrorDetails = (
  error: Readonly<ApiError>,
  { payload }: Readonly<Options> = {},
) =>
  error.errors?.length ?
    error.errors.flatMap((error) =>
      error.loc.map(
        (loc) =>
          t(`fieldErrors.${error.type}`, { ...payload, loc }) ||
          (error.msg ?
            `${loc}: ${error.msg}`
          : `Something went wrong with field: ${loc}`),
      ),
    )
  : ((error.code && t(`errorCodes.${error.code}`, payload)) ??
    (error.message || renderStatusMessage(error.status)));

export const renderErrorBrief = (
  error: unknown,
  options?: Readonly<Options>,
): string => {
  if (!error) return DEFAULT_ERROR_BRIEF;

  if (error instanceof ApiError) {
    return renderApiErrorBrief(error, options);
  }

  if (error instanceof Error && error.message) {
    return error.message;
  }

  return String(error) || DEFAULT_ERROR_BRIEF;
};

export const renderErrorDetails = (
  error: unknown,
  options?: Readonly<Options>,
): null | string | Readonly<string[]> => {
  if (!error) {
    return DEFAULT_ERROR_DETAILS;
  }

  if (error instanceof ApiError) {
    return renderApiErrorDetails(error, options);
  }

  if (error instanceof Error && error.message) {
    return error.message;
  }

  return String(error) || DEFAULT_ERROR_DETAILS;
};

export const renderErrorParts = (
  error: unknown,
  options?: Readonly<Options>,
) => {
  const brief = renderErrorBrief(error, options);
  const details = renderErrorDetails(error, options);

  return {
    brief,
    details: details === brief ? null : details,
  } as const;
};
