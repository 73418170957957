import { Immutable } from 'src/interface/utility';

type ShortDurationSingularUnit =
  | 'millisecond'
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'week';
type ShortDurationPluralUnit = `${ShortDurationSingularUnit}s`;
export type ShortDurationUnit =
  | ShortDurationSingularUnit
  | ShortDurationPluralUnit;

export type TimeFormat =
  | '12hr time'
  | '24hr time'
  | 'full 12hr time'
  | 'full 24hr time';

export type UniversalDateTimeFormat =
  | 'ISODateTiny'
  | 'ISODate'
  | 'ISOTime'
  | 'datetime-local';

export type DateTimeFormat =
  | TimeFormat
  | UniversalDateTimeFormat
  | 'short week day'
  | 'tiny date'
  | 'tiny date time'
  | 'day tiny date'
  | 'full date'
  | 'full date time'
  | 'full date time ms'
  | 'short date'
  | 'short date time'
  | 'short date time 24hr'
  | 'short date 24hr';

// If we ever need all timezones: Intl.supportedValuesOf('timeZone')
// export const pytzTimezones = ['America/New_York', 'Asia/Singapore'] as const;

export const ISO_DATE_REGEXP = /^\d{4}-[01]\d-[0-3]\d$/;
export const ISO_TIME_REGEXP =
  /^[0-2]\d:[0-5]\d(:[0-5]\d(\.\d+)?)?([+-][0-2]\d(:[0-5]\d)?|Z)?$/;
export const ISO_DATETIME_REGEXP =
  /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d(:[0-5]\d(\.\d+)?)?([+-][0-2]\d(:[0-5]\d)?|Z)?$/;

export const UNIVERSAL_DATETIME_FORMATS: Immutable<{
  [Format in UniversalDateTimeFormat]: string;
}> = {
  ISODateTiny: 'yyyyMMdd',
  ISODate: 'yyyy-MM-dd',
  ISOTime: 'HH:mm:ssxxx',
  'datetime-local': "yyyy-MM-dd'T'HH:mm",
} as const;

// Ordering of `millisecondDurationOfUnit` is important for `renderDuration`
//   (requires largest unit first)
export const durationInMilliseconds: {
  [Duration in ShortDurationUnit]: number;
} = {
  week: 1000 * 60 * 60 * 24 * 7,
  weeks: 1000 * 60 * 60 * 24 * 7,
  day: 1000 * 60 * 60 * 24,
  days: 1000 * 60 * 60 * 24,
  hour: 1000 * 60 * 60,
  hours: 1000 * 60 * 60,
  minute: 1000 * 60,
  minutes: 1000 * 60,
  second: 1000,
  seconds: 1000,
  millisecond: 1,
  milliseconds: 1,
} as const;
