import clsx from 'clsx';
import { CSSProperties, ReactNode } from 'react';
import { SortDirection, TableSort } from './interface';
import { CONTENT_CLASS } from './TableCell';

interface Props {
  children?: ReactNode;
  className?: string;
  sortPath?: string;
  isNumeric?: boolean;
  isLockedLeft?: boolean;
  isLockedRight?: boolean;
  sortDirection?: SortDirection;
  style?: CSSProperties;
  onSort?: (sortCallback: TableSort) => void;
}

function HeaderCell({
  children,
  className,
  sortPath,
  isNumeric = false,
  isLockedLeft = false,
  isLockedRight = false,
  sortDirection = 'none',
  style,
  onSort,
}: Props) {
  const handleSortClick = () => {
    onSort?.({
      on: sortPath || '',
      direction: sortDirection === 'descending' ? 'ascending' : 'descending',
    });
  };

  return (
    <th
      style={style}
      className={clsx([
        className,
        'the-table__cell',
        'the-table__cell--header',
        `the-table__cell--sort-direction-${sortDirection}`,
        { 'the-table__cell--sort': !!onSort },
        { 'the-table__cell--is-numeric': isNumeric },
        { 'the-table__cell--is-locked-right': isLockedRight },
        { 'the-table__cell--is-locked-left': isLockedLeft },
      ])}
    >
      {onSort ?
        <button
          type="button"
          className={CONTENT_CLASS}
          onClick={handleSortClick}
        >
          {children}
          <span
            className="the-table__cell-sort-icon"
            aria-label="Click to sort"
          ></span>
        </button>
      : <span className={CONTENT_CLASS}>{children}</span>}
    </th>
  );
}

export default HeaderCell;
