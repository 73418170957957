import type { DataLoadStatus } from 'src/interface/command-center/unsorted-types';
import clsx from 'clsx';
import compact from 'lodash/compact';
import get from 'lodash/get';
import {
  CSSProperties,
  Fragment,
  Key,
  MouseEvent,
  FocusEvent,
  KeyboardEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import EmptyState from 'src/components/common/EmptyState';
import FullWidthLoader from 'src/components/common/FullWidthLoader';
import { isIncompleteStatus } from 'src/interface/command-center/unsorted-type-guards';
import { ApiError } from 'src/interface/errors/api-error';
import useElementSize from 'src/tools/hooks/useElementSize';
import { useHasToggle } from 'src/tools/hooks/useHasToggle';
import { useWindowInnerWidth } from 'src/tools/hooks/window';
import { renderStatusMessage } from 'src/tools/http/renderStatusMessage';
import HeaderCell from './HeaderCell';
import { ColumnDefinition, TableSort } from './interface';
import TableRow from './Row';
import { RowDrawer } from './RowDrawer';
import './Table.scss';

const DEFAULT_ERROR_MESSAGE = 'An error occurred';
const DEFAULT_EMPTY_MESSAGE = 'No data found';

interface Props<RowData> {
  columns: ColumnDefinition[];
  data?: RowData[] | null;
  footerText?: string;
  rowKeyPath?: string;
  rowKey?: (row: RowData) => Key;
  renderRowDrawer?: (rowData: RowData) => ReactNode;
  onRowDrawerToggle?: (rowId: string, newValue: boolean) => void;
  initiallyOpenRowDrawer?: boolean | ((rowData: RowData) => boolean);
  onCheckboxCheck?: (checked: string[]) => void;
  header?: ReactNode;
  className?: string;
  rowsChecked?: string[];
  sort?: TableSort;
  isFetching?: boolean;
  onScrollToBottom?: () => void;
  minTableWidth?: string;
  isFullWidth?: boolean;
  isFullWidthOnMobile?: boolean;

  status?: DataLoadStatus;
  error?: ApiError | null | undefined;
  renderErrorTitle?: ((error: ApiError) => string) | undefined;
  renderErrorDetails?: ((error: ApiError) => string) | undefined;
  emptyTitle?: string;
  emptyDetails?: ReactNode;
  hideHead?: boolean;
}

function Table<RowData>({
  columns,
  data,
  footerText,
  rowKeyPath = 'id',
  rowKey,
  renderRowDrawer,
  onRowDrawerToggle,
  initiallyOpenRowDrawer,
  onCheckboxCheck,
  header,
  rowsChecked = [],
  className,
  sort,
  isFetching = false,
  onScrollToBottom,
  minTableWidth = '0',
  isFullWidth = false,
  isFullWidthOnMobile = false,

  status,
  error,
  renderErrorTitle,
  renderErrorDetails,
  emptyTitle,
  emptyDetails,
  hideHead,
}: Props<RowData>) {
  const wantsTableKeyboardNavigation = useHasToggle(
    'ENABLE_TABLE_KEYBOARD_NAVIGATION',
  );
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const [tableButt, buttIsVisible] = useInView();
  const extraColumnCount = compact([onCheckboxCheck, renderRowDrawer]).length;
  const totalColumnCount = columns.length + extraColumnCount;
  const windowInnerWidth = useWindowInnerWidth();
  const { contentInlineSize: tableWrapperWidth } = useElementSize(
    tableWrapperRef.current,
  );
  const [focusedCell, setFocusedCell] = useState<
    [number, number, number] | null
  >(null);

  const fullWidth = useMemo(
    () => isFullWidth || (isFullWidthOnMobile && windowInnerWidth < 500),
    [isFullWidth, isFullWidthOnMobile, windowInnerWidth],
  );

  useEffect(
    function runScrollToBottomCallback() {
      if (buttIsVisible) {
        onScrollToBottom?.();
      }
    },
    [buttIsVisible, onScrollToBottom],
  );

  const handleCheckboxCheck = (rowId: string) => {
    // This is just to satisfy TypeScript. The checkbox won’t be visible if onCheckboxCheck doesn’t exist
    /* istanbul ignore next */
    if (!onCheckboxCheck) {
      return [];
    }

    let newRowsChecked;
    if (rowsChecked.includes(rowId)) {
      newRowsChecked = rowsChecked.filter((id) => id !== rowId);
    } else {
      newRowsChecked = [...rowsChecked, rowId];
    }
    onCheckboxCheck(newRowsChecked);
    return newRowsChecked;
  };

  const handleRowDrawerToggle = (rowId: string, newValue: boolean) => {
    onRowDrawerToggle?.(rowId, newValue);
  };

  const getFocusedCell = (
    table: HTMLTableElement,
    target: HTMLElement | null,
  ) => {
    if (!target) {
      return {
        coordinate: null,
        cell: null,
      };
    }
    const tbody = target.closest(
      'tbody',
    ) satisfies HTMLTableSectionElement | null;
    const row = target.closest('tr') satisfies HTMLTableRowElement | null;
    const cell = target.closest('td,th') satisfies HTMLTableCellElement | null;

    const tbodyIndex = tbody ? Array.from(table.tBodies).indexOf(tbody) : -1;
    const rowIndex = row ? Array.from((tbody || table).rows).indexOf(row) : -1;
    const cellIndex =
      cell && row?.cells ? Array.from(row?.cells).indexOf(cell) : -1;

    if (!cell || rowIndex === -1 || cellIndex === -1 || tbodyIndex === -1) {
      return {
        coordinate: null,
        cell: null,
      };
    }

    return {
      coordinate: [tbodyIndex, rowIndex, cellIndex] satisfies [
        number,
        number,
        number,
      ],
      cell,
    };
  };

  const handleTableClick = (event: MouseEvent<HTMLTableElement>) => {
    const { cell, coordinate } = getFocusedCell(
      event.currentTarget,
      event.target as HTMLElement | null,
    );

    setFocusedCell(coordinate);
    if (!cell) return;

    cell.setAttribute('tabindex', '0');
    cell.focus();
    cell.addEventListener(
      'blur',
      () => {
        cell.removeAttribute('tabindex');
      },
      { once: true },
    );
  };

  const selector = (cell: NonNullable<typeof focusedCell>) =>
    `tbody:nth-of-type(${cell[0] + 1}) > tr:nth-child(${
      cell[1] + 1
    }) > td:nth-child(${cell[2] + 1}), tbody:nth-of-type(${
      cell[0] + 1
    }) > tr:nth-child(${cell[1] + 1}) > th:nth-child(${cell[2] + 1})`;

  const handleTableFocusedAction = (cell: HTMLTableCellElement) => {
    const firstAction = cell.querySelector('button, a') satisfies
      | HTMLLinkElement
      | HTMLButtonElement
      | null;
    if (firstAction) {
      firstAction.click();
    }
  };

  const handleTableKeyDown = (event: KeyboardEvent<HTMLTableElement>) => {
    // need to store this because browsers sometimes change event.currentTarget
    const currentTarget = event.currentTarget;

    if (event.key === 'Enter') {
      event.preventDefault();
      const { cell } = getFocusedCell(
        event.currentTarget,
        event.target as HTMLElement,
      );
      if (cell) {
        handleTableFocusedAction(cell);
      }
      return;
    }

    const movement =
      event.key === 'ArrowDown' ? [1, 0]
      : event.key === 'ArrowUp' ? [-1, 0]
      : event.key === 'ArrowLeft' ? [0, -1]
      : event.key === 'ArrowRight' ? [0, 1]
      : null;

    if (!movement) {
      return;
    }

    event.preventDefault();

    setFocusedCell((oldFocusedCell) => {
      const newFocusedCell: NonNullable<typeof focusedCell> =
        oldFocusedCell ? [...oldFocusedCell] : [0, 0, 0];
      newFocusedCell[1] += movement[0];
      newFocusedCell[2] += movement[1];

      const properSelector = selector(newFocusedCell);
      const drawerSelector = selector([
        newFocusedCell[0],
        newFocusedCell[1],
        0,
      ]);
      const newCell = (currentTarget?.querySelector(properSelector) ||
        currentTarget?.querySelector(
          drawerSelector,
        )) satisfies HTMLTableCellElement | null;

      if (newCell) {
        newCell.setAttribute('tabindex', '0');
        newCell.focus();
        newCell.addEventListener(
          'blur',
          () => {
            newCell.removeAttribute('tabindex');
          },
          { once: true },
        );
        return newFocusedCell;
      } else {
        return oldFocusedCell || [0, 0, 0];
      }
    });
  };

  const handleFocus = (event: FocusEvent<HTMLTableElement>) => {
    const newFocusedCell = event.target?.closest(
      'th,td',
    ) satisfies HTMLTableCellElement | null;
    if (!newFocusedCell) return;

    const { coordinate } = getFocusedCell(
      event.currentTarget,
      event.target as HTMLElement | null,
    );

    setFocusedCell(coordinate);
  };

  const tableKeyboardNavigationEvents = {
    tabIndex: 0,
    onClick: handleTableClick,
    onKeyUp: handleTableKeyDown,
    onFocus: handleFocus,
  };

  return (
    <>
      <div
        style={{ '--min-table-width': minTableWidth } as CSSProperties}
        className={clsx([
          'the-table',
          { 'the-table--full-width': fullWidth },
          { 'the-table--loading': isFetching },
          { 'the-table--has-footer-text': footerText },
          className,
        ])}
      >
        {header && <div className="the-table__header">{header}</div>}

        <div
          ref={tableWrapperRef}
          className="the-table__table-wrapper"
          style={{ '--table-width': tableWrapperWidth } as CSSProperties}
        >
          <table
            className="the-table__table"
            {...(wantsTableKeyboardNavigation ?
              tableKeyboardNavigationEvents
            : {})}
          >
            <colgroup>
              {onCheckboxCheck && (
                <col className="the-table__column the-table__column--action" />
              )}
              {renderRowDrawer && (
                <col className="the-table__column the-table__column--action" />
              )}
              {columns.map((column) => {
                const customProperties: CSSProperties = {};

                if (typeof column.width === 'number') {
                  customProperties['--col-width'] = `${column.width}px`;
                } else if (column.width && typeof column.width === 'string') {
                  customProperties['--col-width'] = column.width;
                }

                if (typeof column.minWidth === 'number') {
                  customProperties['--col-min-width'] = `${column.minWidth}px`;
                } else if (
                  column.minWidth &&
                  typeof column.minWidth === 'string'
                ) {
                  customProperties['--col-min-width'] = column.minWidth;
                }

                return (
                  <col
                    key={compact([
                      column.label,
                      column.valuePath,
                      column.ariaLabel,
                    ]).join('--')}
                    className="the-table__column"
                    style={customProperties}
                  />
                );
              })}
            </colgroup>

            {hideHead || (
              <thead className="the-table__row-group the-table__row-group--head">
                <tr className="the-table__row">
                  {onCheckboxCheck && (
                    <th className="the-table__cell the-table__cell--row-select"></th>
                  )}

                  {renderRowDrawer && (
                    <th className="the-table__cell the-table__cell--row-drawer-toggle"></th>
                  )}

                  {columns.map((column) => {
                    // Styling the width on colgroup isn’t consistent in Safari
                    const customProperties: CSSProperties = {};

                    if (typeof column.width === 'number') {
                      customProperties['--col-width'] = `${column.width}px`;
                    } else if (
                      column.width &&
                      typeof column.width === 'string'
                    ) {
                      customProperties['--col-width'] = column.width;
                    }

                    if (typeof column.minWidth === 'number') {
                      customProperties['--col-min-width'] =
                        `${column.minWidth}px`;
                    } else if (
                      column.minWidth &&
                      typeof column.minWidth === 'string'
                    ) {
                      customProperties['--col-min-width'] = column.minWidth;
                    }

                    return column.renderHeaderCell ?
                        <Fragment
                          key={compact([
                            column.label,
                            column.valuePath,
                            column.ariaLabel,
                          ]).join('--')}
                        >
                          {typeof column.renderHeaderCell === 'function' ?
                            column.renderHeaderCell(
                              column,
                              sort,
                              customProperties,
                            )
                          : column.renderHeaderCell}
                        </Fragment>
                      : <HeaderCell
                          key={compact([
                            column.label,
                            column.valuePath,
                            column.sortPath,
                            column.ariaLabel,
                          ]).join('--')}
                          style={customProperties}
                          onSort={column.onSort}
                          sortPath={column.sortPath || column.valuePath}
                          isNumeric={column.isNumeric}
                          isLockedLeft={column.isLockedLeft}
                          isLockedRight={column.isLockedRight}
                          sortDirection={
                            (column.sortPath || column.valuePath) === sort?.on ?
                              sort?.direction
                            : 'none'
                          }
                        >
                          {column.hideHeaderLabel ? '' : column.label}
                        </HeaderCell>;
                  })}
                </tr>
              </thead>
            )}

            <tbody className="the-table__row-group  the-table__row-group--body">
              {data?.length ?
                data.map((row) => (
                  <TableRow<RowData>
                    key={rowKey ? rowKey(row) : get(row, rowKeyPath)}
                    rowId={get(row, rowKeyPath) as string}
                    row={row}
                    checked={rowsChecked.includes(get(row, rowKeyPath))}
                    columns={columns}
                    renderDrawer={renderRowDrawer}
                    initallyOpenDrawer={initiallyOpenRowDrawer}
                    onDrawerToggle={
                      onRowDrawerToggle ? handleRowDrawerToggle : undefined
                    }
                    onCheckboxCheck={
                      onCheckboxCheck ? handleCheckboxCheck : undefined
                    }
                  />
                ))
              : error || status ?
                <RowDrawer colSpan={totalColumnCount} faded={false}>
                  {error || status === 'error' ?
                    <EmptyState
                      className="the-table__empty-state"
                      title={
                        !error ? DEFAULT_ERROR_MESSAGE
                        : renderErrorTitle ?
                          renderErrorTitle(error)
                        : error.status ?
                          renderStatusMessage(error.status)
                        : DEFAULT_ERROR_MESSAGE
                      }
                      description={
                        !error ? undefined
                        : renderErrorDetails ?
                          renderErrorDetails(error)
                        : error.message || undefined
                      }
                    />
                  : status && isIncompleteStatus(status) ?
                    <FullWidthLoader className="the-table__loader" />
                  : <EmptyState
                      className="the-table__empty-state"
                      title={emptyTitle || DEFAULT_EMPTY_MESSAGE}
                      description={emptyDetails}
                    />
                  }
                </RowDrawer>
              : null}
            </tbody>
          </table>
        </div>

        <div ref={tableButt} className="the-table__row the-table__row--butt">
          <div className="the-table__cell">
            {buttIsVisible && (!status || !!data) && !!onScrollToBottom && (
              <FullWidthLoader />
            )}
          </div>
        </div>
      </div>

      {footerText && <div className="the-table-footer-text">{footerText}</div>}
    </>
  );
}

export default Table;
