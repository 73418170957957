import clsx from 'clsx';
import {
  DoubleCcPart1,
  SvgWrapper,
  DoubleCcPart2,
  DoubleCcPart3,
  DoubleCcPart4,
  FatInfinity,
  DoubleGroup,
  FatGroup,
} from './Svg/CcLogo/LogoSvgPieces';
import './LogoLoader.scss';

interface Props {
  isLoading?: boolean;
  className?: string;
}

function LogoLoader({ className, isLoading = true }: Props) {
  if (!isLoading) {
    return null;
  }

  return (
    <SvgWrapper
      className={clsx(['logo-loader', className])}
      role="status"
      aria-label="loading"
    >
      <DoubleGroup>
        <DoubleCcPart1 className="logo-loader__logo-part-1" />
        <DoubleCcPart2 className="logo-loader__logo-part-2" />
        <DoubleCcPart3 className="logo-loader__logo-part-3" />
        <DoubleCcPart4 className="logo-loader__logo-part-4" />
      </DoubleGroup>
      <FatGroup>
        <FatInfinity className="logo-loader__inifinity" />
      </FatGroup>
    </SvgWrapper>
  );
}

export default LogoLoader;
