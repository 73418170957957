import { captureException } from '@sentry/react';
import cloneDeep from 'lodash/cloneDeep';
import { Primitive } from 'src/interface/utility';

type ClonableError =
  | Error
  | EvalError
  | RangeError
  | ReferenceError
  | SyntaxError
  | TypeError
  | URIError;
// to be supported later, some browsers already do
// | AggregateError

type ClonableErrorName =
  | 'Error'
  | 'EvalError'
  | 'RangeError'
  | 'ReferenceError'
  | 'SyntaxError'
  | 'TypeError'
  | 'URIError';
// to be supported later, some browsers already do
// | 'AggregateError';

type Clonable =
  | (ClonableError & { name: ClonableErrorName })
  | { [Key: string | number]: Clonable }
  | Omit<Primitive, symbol>
  | Map<Clonable, Clonable>
  | Array<Clonable>
  | Set<Clonable>
  | ArrayBuffer
  | boolean
  | DataView
  | RegExp
  // eslint-disable-next-line @typescript-eslint/ban-types -- this is String object, not string
  | String
  | Date
  // web only
  | Blob
  | CryptoKey
  | DOMException
  | DOMMatrix
  | DOMMatrixReadOnly
  | DOMPoint
  | DOMPointReadOnly
  | DOMQuad
  | DOMRect
  | DOMRectReadOnly
  | File
  | FileList
  | FileSystemDirectoryHandle
  | FileSystemFileHandle
  | FileSystemHandle
  | ImageBitmap
  | ImageData
  | RTCCertificate
  | VideoFrame;
// not yet in typescript
// | AudioData
// | CropTarget
// | GPUCompilationInfo
// | GPUCompilationMessage

function cloneWithLodash<Value extends Clonable>(valueToClone: Value) {
  return cloneDeep(valueToClone);
}

function cloneWithStructuredClone<Value extends Clonable>(valueToClone: Value) {
  try {
    return structuredClone(valueToClone);
  } catch (_error) {
    const error = _error as Error;
    // TODO: start using cause instead of changing error messages
    // mobile safari won’t let us change the error message
    try {
      error.message = `Tried to clone ${valueToClone}: ${error.message}`;
    } catch (e) {
      // ignore iOS Safari
    }
    captureException(error, { extra: { triedToClone: valueToClone } });

    return cloneWithLodash(valueToClone);
  }
}

export default 'structuredClone' in window ?
  cloneWithStructuredClone
: cloneWithLodash;
