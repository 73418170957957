import clsx from 'clsx';
import { ButtonHTMLAttributes, ReactNode, Ref, forwardRef, useId } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import './Button.scss';

export const IS_BUTTON_SYMBOL = Symbol('Button');

export type ButtonColor =
  | 'default'
  | 'text'
  | 'text-destructive'
  | 'call-to-action'
  | 'destructive';

type ButtonShape =
  | 'pill' //  also circle
  | 'rounded'
  | 'sharp';

export type ButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement> & LinkProps,
  'type' | 'shape' | 'to' | 'ref'
> & {
  autoFocus?: boolean;
  color?: ButtonColor;
  type?: JSX.IntrinsicElements['button']['type'] | 'link' | 'tab';
  rounding?: ButtonShape;

  isActive?: boolean;
  allowInteractionWhenDisabled?: boolean;
  inputId?: string;
  linkTo?: string;
  children?: ReactNode;

  insetFocus?: boolean;

  touchingTop?: boolean;
  touchingBottom?: boolean;
  touchingLeft?: boolean;
  touchingRight?: boolean;

  onClick?: () => void;
  onFocus?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

function TheButton(
  {
    autoFocus = false,
    color = 'default',
    type: typeProps,

    className,
    disabled,
    isActive,
    allowInteractionWhenDisabled = false,
    inputId: propsInputId,
    linkTo,
    rounding: shape = 'rounded',
    children,

    insetFocus,

    touchingTop,
    touchingBottom,
    touchingLeft,
    touchingRight,

    onClick,
    onFocus,
    onMouseEnter,
    onMouseLeave,

    ...htmlButtonProps
  }: ButtonProps,
  ref: Ref<HTMLSpanElement | HTMLAnchorElement | HTMLButtonElement>,
) {
  const id = useId();
  const inputId = propsInputId ?? id;
  const type = (!typeProps && !!linkTo ? 'link' : typeProps) ?? 'button';

  const handleClick = () => {
    if (!onClick || (disabled && !allowInteractionWhenDisabled)) return;

    onClick();
  };

  const sharedButtonProps = {
    id: inputId,
    className: 'the-button__button',
    onClick: handleClick,
    onFocus: onFocus,
    onMouseEnter: onMouseEnter,
    onMouseLeave: onMouseLeave,
  };

  return (
    <span
      className={clsx([
        className,
        'the-button',
        `the-button--color-${color}`,
        `the-button--type-${linkTo ? 'link' : type}`,
        `the-button--shape-${shape}`,
        {
          'the-button--inset-focus': insetFocus,
          'the-button--is-disabled': disabled,
          'the-button--is-active': isActive,
          'the-button--has-link': type === 'link' && linkTo,
          'the-button--has-action': onClick || type === 'submit',
          'the-button--touch-top': touchingTop,
          'the-button--touch-bottom': touchingBottom,
          'the-button--touch-left': touchingLeft,
          'the-button--touch-right': touchingRight,
        },
      ])}
    >
      {type === 'link' ?
        <Link
          ref={ref as Ref<HTMLAnchorElement>}
          {...sharedButtonProps}
          {...(htmlButtonProps as LinkProps)}
          to={linkTo ?? ''}
          aria-selected={isActive}
        >
          {children}
        </Link>
      : type === 'tab' ?
        <span
          ref={ref as Ref<HTMLSpanElement>}
          {...sharedButtonProps}
          aria-selected={isActive}
          role="tab"
        >
          {children}
        </span>
      : onClick || type === 'submit' ?
        <button
          ref={ref as Ref<HTMLButtonElement>}
          {...sharedButtonProps}
          {...htmlButtonProps}
          autoFocus={autoFocus}
          disabled={disabled && !allowInteractionWhenDisabled}
          aria-disabled={disabled}
          type={
            type === 'button' ? 'button'
            : type === 'submit' ?
              'submit'
            : 'reset'
          }
        >
          {children}
        </button>
      : <span ref={ref} {...sharedButtonProps}>
          {children}
        </span>
      }
    </span>
  );
}

TheButton.symbol = IS_BUTTON_SYMBOL;

export default forwardRef(TheButton);
